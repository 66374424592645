<template>
    <div>

      <b-row>  
        <b-col class="col-12 col-lg-9">
          <b-col> 
            <mentionsTables :username="tiktokData.basic.username"/>
          </b-col>
          <b-col>
            <features-one
              :languages="tiktokData.features.audience_languages.data"
              :countries="tiktokData.features.audience_geo.data.countries"
              :age_gender="(tiktokData.features.audience_age_gender.data !== null) ? tiktokData.features.audience_age_gender.data : {}"
              :audience_races="(tiktokData.features.audience_races.data !== null) ? tiktokData.features.audience_races.data : {}"
              :audience_by_type="(tiktokData.features.audience_by_type.data !== null) ? tiktokData.features.audience_by_type.data : {}"
              :audience_reachability="(tiktokData.metrics.audience_reachability !== null) ? tiktokData.metrics.audience_reachability : {}"
              :username="tiktokData.basic.username"
            />
          </b-col>
        </b-col>
        <b-col class="col-12 col-lg-3">
          <titktokRecommends
            :media="tiktokData.features.blogger_views_likes_chart.data"
            :username="tiktokData.basic.username"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
            <metrics 
              :subscribers_count="tiktokData.metrics.subscribers_count.performance"
              :subscribers_growth_prc="tiktokData.metrics.subscribers_growth_prc.performance"
              :er="tiktokData.metrics.er.performance"
              :alikes_avg="tiktokData.metrics.alikes_avg.performance"
            />
          </b-col>
          <b-col class="col-12">
            <features-two
              :blogger_prices="(tiktokData.features.blogger_prices.data !== null) ? tiktokData.features.blogger_prices.data : {} "
              :blogger_challenges_performance="blogger_challenges_performance"
              :likes_distribution="tiktokData.features.likes_distribution.data"
              :blogger_views_likes_chart="tiktokData.features.blogger_views_likes_chart.data"
              :channel_id="tiktokData.basic.id"
              :audience_sentiments="(tiktokData.features.audience_sentiments !== null) ? tiktokData.features.audience_sentiments : {}"
            />
          </b-col>
      </b-row>
    </div>
</template>
<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    titktokRecommends: () => import('./titktokRecommends.vue'),
    metrics: () => import('./metrics.vue'),
    featuresTwo: () => import('./featuresTwo.vue'),
    featuresOne: () => import('./featuresOne.vue'),
    mentionsTables: () => import('./mentionsTables.vue')
  },
  props: {
    tiktokData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      blogger_challenges_performance: {}
    }
  },
  created() {
    if (this.tiktokData && this.tiktokData.features && this.tiktokData.features.blogger_challenges_performance && this.tiktokData.features.blogger_challenges_performance.data && this.tiktokData.features.blogger_challenges_performance.data.challenges_stats.performance) this.blogger_challenges_performance = this.tiktokData.features.blogger_challenges_performance.data.challenges_stats.performance
  }
}
</script>
